import React from 'react'
import Banner from '../../banner'

import SectionOne from './SectionOne'


const BookOnlinePage = () => (
    <>
    <Banner />
    <SectionOne />
    </>
)

export default BookOnlinePage